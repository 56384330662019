<template>
    <div>
        <el-row >
            <el-button style="float: left; margin: 7px;" size="mini" type="primary" icon="el-icon-edit" @click="handleEdit" >
            {{$t('Edit')}}
            </el-button>
        </el-row>
        <el-row :gutter="20" class="profile">
            <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11" style="text-align: right;">
                {{$t('Account')}}
            </el-col>
            <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11" style="text-align: left; padding-left: 20px;">
                {{accountData.account}}
            </el-col>
        </el-row>
        <el-row :gutter="20" class="profile">
            <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11" style="text-align: right;">
                {{$t('YourName')}}
            </el-col>
            <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11" style="text-align: left; padding-left: 20px;">
                {{ accountData.name }}
            </el-col>
        </el-row>
        <el-row :gutter="20" class="profile">
            <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11" style="text-align: right;">
                {{$t('TelePhone')}}
            </el-col>
            <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11" style="text-align: left; padding-left: 20px;">
                {{ accountData.phone }}
            </el-col>
        </el-row>
        <el-row :gutter="20" class="profile">
            <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11" style="text-align: right;">
                {{$t('Role')}}
            </el-col>
            <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11" style="text-align: left; padding-left: 20px;">
                <el-tag v-for="(role) in accountData.roleInfo.roleData" :key="role.id">
                    {{ role.name }}
                </el-tag>
            </el-col>
        </el-row>
        <AccountSetting :dialogVisible="dialogSettingVisible" :accountData="accountData" :isProfileEditing="isProfileEditing" @close="dialogSettingOnClose()" />
    </div>
</template>
<script>
import { mixins } from '@/views/common/mixins.js'
import AccountSetting from './AccountSetting.vue'
import {refreshToken,stopRefreshToken} from "@/utils/auth";
import {apiUrl} from "@/utils/main";
export default {
    name: 'Profile',
    components: {
        AccountSetting
    },
    mixins: [mixins],
    data() {
        return {
            dialogSettingVisible: false,
            isProfileEditing: true,
            accountData: {
                id: undefined,
                account: undefined,
                roleInfo: {roleData: []}
            }
        }
    },
    async created() {
        this.loadCurrentAccount();
        console.log('@currentAccount=', this.$store.state.currentAccount)
        //await refreshToken();
    },
    beforeDestroy(){
      console.log("call beforeDestroy");
      //stopRefreshToken();
    },
    methods: {
        loadCurrentAccount() {
            this.axios.get('/api/v1/common/currentAccount', {}).then(res => {
                //console.log(res)
                this.accountData = res.data
                console.log(this.accountData)
            })
        },
        handleEdit: function() {
            this.dialogSettingVisible = true
        },
        dialogSettingOnClose() {
            this.dialogSettingVisible = false
            this.loadCurrentAccount()
        }
    }
}
</script>
<style type="text/css">
.profile{
    padding: 20px;
}
</style>
