<template>
    <el-dialog width="600" :title="$t('AccountSettings')" :visible="dialogVisible" @close="dialogDataOnClose()">
        <el-form ref="dataForm" :rules="rules" :model="updateQuery" size="small" >
            <el-row :gutter="20">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="account" :label="$t('Account')">
                        <el-input id="account" size="small" v-model="updateQuery.account"
                            :placeholder="$t('EnterAdminAccount')" class="filter-item custom-width" clearable 
                            v-bind:readonly="isModify" :disabled="isModify" />
                    </el-form-item>
                </el-col>
                <el-col v-if="isProfileEditing || !isSuperAdmin" :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="operator" :label="$t('Operator')" >
                        <input id="operator" type="hidden" name="serviceId" class="form-control custom-width"
                            v-model="updateQuery.serviceId">
                        {{ updateQuery.serviceName }}
                    </el-form-item>
                </el-col>
                <!-- 如果為superadmin 提供下拉是選單; 如果為一般營運商admin 則不提供 -->
                <el-col v-else :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="serviceId" :label="$t('Operator')" >
                        <el-select id="serviceId" v-model="updateQuery.serviceId" clearable
                            :placeholder="$t('SelectAnOperator')" class="filter-item custom-width" >
                            <el-option v-for="item in this.serviceList" :key="item.id"
                                :label="item.name + ' (' + item.code + ')'" :value="item.id" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20"> <!-- v-if="!isModify" -->
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="digest" :label="$t('Password')" >
                        <el-input id="digest" type="password" size="small" v-model="updateQuery.digest" 
                            :placeholder="$t('EnterPassword')" class="filter-item custom-width"  autocomplete="one-time-code" clearable/>
                            <!-- readonly onfocus="if (this.hasAttribute('readonly')) { this.removeAttribute('readonly');this.blur();this.focus();  }"  -->
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="redigest" :label="$t('Password')+'('+$t('Confirm')+')'" >
                        <el-input id="redigest" type="password" size="small" v-model="updateQuery.redigest" :placeholder="$t('Password')+'('+$t('Confirm')+')'" class="filter-item custom-width" 
                            autocomplete="one-time-code" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="name" :label="$t('YourName')" >
                        <el-input id="name" size="small" v-model="updateQuery.name" :placeholder="$t('InputYourName')"
                            class="filter-item custom-width" clearable  />
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="phone" :label="$t('TelePhone')" >
                        <el-input id="phone" size="small" v-model="updateQuery.phone"
                            :placeholder="$t('InputTelephoneNum')" class="filter-item custom-width" clearable  />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" v-show="!isProfileEditing">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="enabled" :label="$t('Status')" >
                        <el-select id="enabled" v-model="updateQuery.enabled" :placeholder="$t('WhetherToEnable')"
                            size="small" class="filter-item custom-width" clearable>
                            <el-option :label="$t('Enable')" :value="true" />
                            <el-option :label="$t('Disable')" :value="false" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" v-if="isModify">                
                <el-col :xs="22">
                    <div style="margin-bottom: 20px;">{{$t('AccountSettingsNote')}}</div>
                </el-col>
            </el-row>
            
        </el-form>
        <el-button style="margin-bottom: 0px; margin-top: 10px;" size="median" type="primary"
            @click="updateData()">{{$t('Save')}}</el-button>

        <br/><br/>
    </el-dialog>
</template>

<script>
import { mixins } from '@/views/common/mixins.js'
import {apiUrl} from "@/utils/main";

export default {
    name: 'AccountSetting',
    mixins: [mixins],
    props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
        accountData: {
            require: false,
            default: undefined,
            type: Object
        },
        dialogVisible: {
            require: true,
            default: undefined,
            type: Boolean
        },
        isProfileEditing: {
            required: false,
            default: false,
            type: Boolean
        }
    },
    data() {
        let mailValid = (rule, value, callback) => {
            if (/^[^@]+@\w+(\.\w+)+\w$/.test(value)) {
                callback();
            } else {
                //callback(new Error("帳號格式不正確，帳號為信箱格式"));//替換語系
                callback(new Error(this.$t('IncorrectAccount')));

            }

        };
        let digestValid = (rule, value, callback) => {
            // 檢查是否包含至少一個大小寫字母、數字和特殊符號
            if (this.isModify && (value==undefined || value.length==0)){
                callback();
            } else {
                
                if (value != null && value != undefined && value.length >=9 && /[A-Z]/.test(value) && /[a-z]/.test(value) && /\d/.test(value) && /[^A-Za-z0-9]/.test(value)) {
                    callback();
                } else {
                    //callback(new Error("與密碼不相同"));//替換語系
                    callback(new Error("密碼長度至少有九位數，應包含大小寫字母、數字、特殊符號的組合。"));
                }
            }

        };
        let redigestValid = (rule, value, callback) => {
            if( (this.updateQuery.digest == null || this.updateQuery.digest.length == 0 ) && (value == null || value.length == 0 )){
                callback();
            } else if (value == this.updateQuery.digest) {
                callback();
            } else {
                //callback(new Error("與密碼不相同"));//替換語系
                callback(new Error(this.$t('DifferentPassword')));
            }

        };
        let phoneValid = (rule, value, callback) => {
            if( value == null || value.length == 0 ){
                callback();
            } else if (/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{2,}$/.test(value)) {
                callback();
            } else {
                callback(new Error("請輸入8碼以上的電話號碼(支援數字與-符號)"));
            }

        };
        return {
            dialogWidth: 0,
            total: 0,
            list: [],
            listLoading: false,
            isModify: false,
            serviceList: [],
            updateQuery: {
                id: undefined,
                account: undefined,
                digest: undefined,
                redigest: undefined,
                name: undefined,
                // 預設帶入建立者的營運商資訊
                service: undefined,
                serviceId: undefined,
                serviceName: undefined,
                roles: undefined,
                phone: undefined,
                memo: undefined,
                enabled: true
            },
            isSuperAdmin: false,
            currentAccount: undefined,
            rules: {
                serviceId: [{ required: true, message: this.$t('Required'), trigger: 'blur' }],
                enabled: [{ required: true, message: this.$t('Required'), trigger: 'blur' }],
                name: [{ required: true, message: this.$t('Required'), trigger: 'blur' }],
                account: [{ required: true, validator: mailValid, trigger: 'blur' }],
                digest: [{ required: true, validator: digestValid, trigger: 'blur' }],
                redigest: [{ required: true, validator: redigestValid, trigger: 'blur' }],
                phone: [{ required: false, validator: phoneValid, trigger: 'blur' }],
            }
        };
    },
    computed: {
        isRefresh() {
            return this.$store.state.currentAccount; // 需要監聽的數據
        }
    },
    watch: {
        accountData(val) {
            if (val) {
                this.isModify = true
                console.log("isModify:" + this.isModify);
                this.updateQuery.id = val.id
                this.updateQuery.account = val.account
                this.updateQuery.digest = val.digest
                this.updateQuery.name = val.name
                this.updateQuery.phone = val.phone
                this.updateQuery.memo = val.memo
                this.updateQuery.enabled = val.enabled
                this.updateQuery.serviceId = val.serviceInfo.id
                this.updateQuery.serviceName = val.serviceInfo.name
                this.updateQuery.service = val.serviceInfo
                this.updateQuery.roles = val.roles
                this.rules.digest[0].required = false
                this.rules.redigest[0].required = false
            } else {
                this.resetUpdateQuery()
            }
        },
        'updateQuery.digest': function () {
            if(this.isModify) {
                if(this.updateQuery.digest == "") {
                    this.rules.digest[0].required = false
                    this.rules.redigest[0].required = false
                } else {
                    this.rules.digest[0].required = true
                    this.rules.redigest[0].required = true
                }
           
            }
        },
        isProfileEditing(val) {
            console.log("isProfileEditing:" + val)
        },
        isRefresh(val){
            this.currentAccount = val
            this.permissionHandler();
        }

    },
    async created() {
        this.currentAccount = this.$store.state.currentAccount;
        this.permissionHandler();
    },
    methods: {
        dialogDataOnClose() {
            this.resetUpdateQuery()
            this.$refs.dataForm.resetFields() // 表單校驗結果重置
            this.$emit('close') // 觸發父層 close
        },
        getServiceOptions() {
            this.axios.get('/api/v1/common/service/enabled').then(res=> {
                this.serviceList = res.data
            }).catch( error => {
                console.err(error)
            })
        },
        // 一般篩選條件上傳
        updateData() {
            this.$refs['dataForm'].validate((valid) => {
                if (valid) {
                    // update
                    this.axios.put('/api/v1/account', this.updateQuery).then(() => {
                        this.$message({
                            showClose: true,
                            message: this.$t('SuccessEdited'),
                            type: 'success'
                        })
                        
                        if(this.isProfileEditing) {
                            this.$store.dispatch("getCurrentAccount");
                        }

                        this.dialogDataOnClose()
                    }).catch(err => {
                        console.log('err:' + err)
                        this.$message({
                            message: this.$t('FailEdited'),
                            type: 'error'
                        })
                    })
                } else {
                    this.$message({
                        message: this.$t('IncorrectFormat'),
                        type: 'error'
                    })
                }
            })
        },
        resetUpdateQuery() {
            this.isModify = false
            this.updateQuery = {
                id: undefined,
                account: undefined,
                digest: undefined,
                redigest: undefined,
                name: undefined,
                service: this.$store.state.currentAccount.serviceInfo,
                serviceId: this.$store.state.currentAccount.serviceInfo.id,
                roles: undefined,
                phone: undefined,
                memo: undefined,
                enabled: true,                
            }
            this.rules.digest[0].required = true
            this.rules.redigest[0].required = true
        },
        permissionHandler() {
            if(this.currentAccount != undefined) {
            console.log('@currentAccount=', this.currentAccount)
            this.isSuperAdmin = false
            this.currentAccount.roleInfo.roleData.forEach((role) => {
                if(role.name == "SUPERADMIN") {
                    this.getServiceOptions();
                    this.isSuperAdmin = true;
                }
                console.log("role:" ,role);
            });

            if(!this.isSuperAdmin) {
                    console.log("watch: is not superadmin")
                    this.updateQuery.service = this.currentAccount.serviceInfo
                    this.updateQuery.serviceId = this.currentAccount.serviceInfo.id
                    this.updateQuery.serviceName = this.currentAccount.serviceInfo.name
                }
            }
        }
    }
}
</script>

<style>
.el-form-item__error {
    top: 80%
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 767.98px) { 
    .el-form-item__content {
        text-align: left;
    }
}

@media (min-width: 768px) { 
    .custom-width {
        width: 200px;
    }

    .el-form-item__content {
        text-align: left;
        margin-left: 100px;
    }

    .el-form-item__label {
        width: 100px;
    }
 }
</style>
